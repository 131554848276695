import React, { useEffect, useRef, useState } from 'react'
import { Button, Container, Stack, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom'
import axios from 'axios';
import { useReactToPrint } from "react-to-print";
import { Url } from '../url/url';

const Invoice = React.forwardRef(({ order }, ref) => { 
  return (
    <main className="container" ref={ref} style={{ padding: "10px" }}>
      {/* Invoice Header */}
      <img src="/image/logo.png" alt="Logo" width="200" />
      <h4 className='text-center'>INVOICE</h4>
      <div className="my-3 border-bottom pb-3">
        <div className="d-flex justify-content-between align-items-center gap-4">
          <div> 
            <p className="mb-1 fw-bold">Pikoli Design Consultancy LLP</p>
            <p className="mb-1">C-26, sector-65, Noida, Gautam Buddha Nagar, Uttar Pradesh, 201301</p>
            <p className="mb-1"><span className="fw-bold"> GSTIN :</span> <span>09AAYFP1503B1ZB</span></p> 
          </div>

          <div>
            <p className="mb-1"><span className="fw-bold"> Order No. :</span> <span> {order._id}</span></p>
            <p className="mb-1"><span className="fw-bold"> Invoice No :</span> <span> IN-{order._id}</span></p>

            <p className="mb-1"><span className="fw-bold"> Invoice Date :</span> <span>{new Date().toLocaleDateString()}</span></p>

            {/* <p className="textPrimary fw-bold text-uppercase">Shipping Address :</p>
            <div className="border p-2">
              <p className="border-bottom mb-1 pb-1 text-capitalize">{order.address.receiver}</p>
              {order.address.nearBy ?
                <p className="mb-0">{order.address.nearBy}</p> : null}
              <p className="mb-0 text-capitalize">{order.address.address} , {order.address.city} , {order.address.state} , {order.address.pincode}</p>
              <p className="mb-0">Contact : 9876543210</p>
            </div> */}
          </div>
        </div>
      </div>

      {/* Billing & Shipping Addresses */}
      <div className="mb-3 pb-3">
        <p className="textPrimary fw-bold text-uppercase">Shipping Address :</p>
        {/* <div className="border p-2"> */}
        <p className="mb-1 pb-1 fw-bold text-capitalize">{order.address.receiver}</p>
        {order.address.nearBy ?
          <p className="mb-0">Landmark : {order.address.nearBy}</p> : null}
        <p className="mb-0 text-capitalize">{order.address.address} , {order.address.city} , {order.address.state} , {order.address.pincode}</p>
        {/* <p className="mb-0">Contact : 9876543210</p> */}
        {/* </div> */}
      </div>
      {/* <div className="mb-3 pb-3">
        <div className="row">
          <div className="col-8">
              <p className="textPrimary fw-bold text-uppercase">Billing Address :</p>
              <div className="border p-2">
                <p className="border-bottom mb-1 pb-1 text-capitalize">John Doe</p>
                <p className="mb-0">456 Sample Road, Some City, Some State, 654321</p>
                <p className="mb-0">Contact : 9876543210</p>
              </div>
            </div>
          <div className="col-8">
            <p className="textPrimary fw-bold text-uppercase">Shipping Address :</p>
            <div className="border p-2">
              <p className="border-bottom mb-1 pb-1 text-capitalize">{order.address.receiver}</p>
              {order.address.nearBy ?
                <p className="mb-0">{order.address.nearBy}</p> : null}
              <p className="mb-0 text-capitalize">{order.address.address} , {order.address.city} , {order.address.state} , {order.address.pincode}</p>
              <p className="mb-0">Contact : 9876543210</p>
            </div>
          </div>
        </div>
      </div> */}

      {/* Order Details Table */}
      <div>
        <table className="table table-bordered">
          <thead className="bgPrimary text-white">
            <tr>
              <th>Item</th>
              <th>Price</th>
              <th className="text-center">Quantity</th>
              <th className="text-end">Total</th>
            </tr>
          </thead>
          <tbody>
            {order && order?.product?.map(value => (
              <tr key={value._id}>
                <td>{value.productName}</td>
                <td className='d-block' style={{ minWidth: "max-content" }}>  ₹ {value.discountedPrice}   </td>
                <td className="text-center">{value.quantity}</td>
                <td className="text-end d-block" style={{ minWidth: "max-content" }}>₹ {value.totalPrice}</td>
              </tr>
            ))}

            <tr>
              <td colSpan="2" />
              <td>
                <p className="mb-1"> SubTotal </p>
                {/* <p className="mb-1"> Discount (-) </p> */}
                {/* <p className="mb-1"> Extra Discount (Coupon) </p> */}
              </td>
              <td>
                <p className="text-end mb-1">₹ {order.subtotal}</p>
                {/* <p className="text-end mb-1">₹ 100</p> */}
                {/* <p className="text-end mb-1">₹ 50</p> */}
              </td>
            </tr>

            <tr className="bg-light">
              <td colSpan="2">
                <p className="text-uppercase mb-0"> payment receipt </p>
              </td>
              <td colSpan="2" />
            </tr>

            <tr>
              <td colSpan="2">
                <p className="mb-1"> Total (Inclusive of all Taxes) </p>
              </td>
              <td colSpan="2">
                <p className="text-end mb-1">₹ {order.subtotal}</p>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                {/* <p className="mb-1"> Amount Due On Delivery </p> */}
              </td>
              <td colSpan="2">
                <p className="text-end mb-1 text-capitalize">{order.paymentType === "cod" ? "cash on delivery" : "prepaid"}</p>

              </td>
            </tr>
          </tbody>
        </table>
      </div> 
      <div className="mt-5">
        <h6 className="text-uppercase">Disclaimer :</h6>
        <ol>
          <li className="fs-12">This invoice is generated based on the details provided at the time of purchase.</li>
          <li className="fs-12">Please retain this invoice for future reference regarding returns, warranties, or support inquiries.</li>
          <li className="fs-12">All prices are inclusive of applicable taxes unless otherwise stated.</li>
          <li className="fs-12">In case of discrepancies, kindly contact our support team within 7 days of the invoice date.</li>
          <li className="fs-12">Products once sold are subject to the return and refund policies of the company.</li>
          <li className="fs-12">This invoice is system-generated and does not require a physical signature.</li>
        </ol>
      </div>
    </main>
  ) 
});

const OrderView = () => {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const [awbId, setAwbId] = useState(null)
  const [shipmentTrack, setShipmentTrack] = useState(null)
 
  const orderTrackFunc = async () => {
    if (awbId) {
      // console.log("awb_code", awbId);
      try {
        const resp = await axios.get(`${Url}/api/order/track-order/${awbId}`)
        if (resp.status === 200 && resp?.data?.tracking_data) {
          setShipmentTrack(resp?.data?.tracking_data.shipment_track_activities)
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  }

  const orderFind = async (odrId) => {
    if (odrId) {
      const response = await axios.get(`${Url}/api/order/singleOrder/${odrId}`)
      if(response.status === 200){
        setOrder(response.data)
        if (response?.data?.shipingDetails) {
          setAwbId(response?.data?.shipingDetails?.awb_code)
        }
      }
    }
  }

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const amPm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    hours = String(hours).padStart(2, '0');
    return `${hours}:${minutes} ${amPm}`;
  }

  const formatedDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(date).toLocaleDateString('en-IN', options);
  }


  const fetchDocuments = async () => {
    if(!order){ 
      return
    }
    try { 
        const response = await axios.post(`${Url}/api/order/print-label-invoice`, { shipmentId:order.shipingDetails.shipment_id, orderId: order.shipingDetails.order_id } )
        console.log("response", response);
        
        const data = await response.data 
        if (data) {
        // if (data.labelUrl && data.invoiceUrl) {
            window.open(data.labelUrl, "_blank");  // Open Shipping Label
            window.open(data.invoiceUrl, "_blank"); // Open Invoice
        } else {
            alert("Failed to fetch documents.");
        }
    } catch (error) {
        console.error("Error fetching documents:", error);
    }
};
const printLabel = async () => {
  if (!order) return;

  try {
      const response = await axios.post(`${Url}/api/order/print-label`, { 
          shipmentId: order.shipingDetails.shipment_id 
      }, { responseType: 'blob' }); // Fetch as blob (PDF)

      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Create an iframe element to hold the PDF
      const iframe = document.createElement("iframe");
      iframe.style.display = "none";  // Hide iframe
      iframe.src = pdfUrl;

      document.body.appendChild(iframe);

      iframe.onload = () => {
          iframe.contentWindow.print(); // Print the PDF
          setTimeout(() => {
              document.body.removeChild(iframe); // Remove iframe after printing
              URL.revokeObjectURL(pdfUrl); // Free up memory
          }, 1000);
      };
  } catch (error) {
      console.error("Error fetching documents:", error);
      alert("Failed to fetch documents.");
  }
};

  const fetchLabel2 = async () => {
    if(!order){ 
      return
    }
    try { 
        const response = await axios.post(`${Url}/api/order/print-label`, { shipmentId:order.shipingDetails.shipment_id  } )
        console.log("response", response);
        
        const data = await response.data  
        if (data.labelUrl) { 
            window.open(data.labelUrl, "_blank");  
        } else {
            alert("Failed to fetch documents.");
        }
    } catch (error) {
        console.error("Error fetching documents:", error);
    }
};
  const fetchInvoice2 = async () => {
    if(!order){ 
      return
    }
    try { 
        const response = await axios.post(`${Url}/api/order/print-invoice`, { orderId: order.shipingDetails.order_id } )
        console.log("response", response);
        
        const data = await response.data  
        if (data.invoiceUrl) { 
            window.open(data.invoiceUrl, "_blank"); // Open Invoice
        } else {
            alert("Failed to fetch documents.");
        }
    } catch (error) {
        console.error("Error fetching documents:", error);
    }
};
const fetchLabel = async () => {
  if (!order) return;

  try {
      const response = await axios.post(`${Url}/api/order/print-label`, { 
          shipmentId: order.shipingDetails.shipment_id 
      });

      const { labelUrl } = response.data; 
      if (!labelUrl) {
          alert("Failed to get label URL.");
          return;
      }

      // Fetch the actual PDF file from the labelUrl
      const pdfResponse = await axios.get(labelUrl, { responseType: "blob" });

      // Create a blob and download
      const pdfBlob = new Blob([pdfResponse.data], { type: "application/pdf" });
      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = `Shipping_Label_${order.shipingDetails.shipment_id}.pdf`;
      document.body.appendChild(link);
      link.click();
      
      document.body.removeChild(link);
      URL.revokeObjectURL(pdfUrl);

  } catch (error) {
      console.error("Error fetching document:", error);
      alert("Failed to download document.");
  }
};
const fetchInvoice = async () => {
  if (!order) return;

  try {
      const response = await axios.post(`${Url}/api/order/print-invoice`, { 
          orderId: order.shipingDetails.order_id 
      });

      const { invoiceUrl } = response.data;
      if (!invoiceUrl) {
          alert("Failed to get invoice URL.");
          return;
      }

      // Fetch the actual PDF file from the invoice URL
      const pdfResponse = await axios.get(invoiceUrl, { responseType: "blob" });

      // Create a blob and download
      const pdfBlob = new Blob([pdfResponse.data], { type: "application/pdf" });
      const pdfUrl = URL.createObjectURL(pdfBlob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = `Invoice_${order.shipingDetails.order_id}.pdf`;
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(pdfUrl);

  } catch (error) {
      console.error("Error fetching invoice:", error);
      alert("Failed to download invoice.");
  }
};









  const contentRef = useRef(null);
  const handlePrint = useReactToPrint({ contentRef });

  useEffect(() => {
    orderFind(orderId)
  }, [])

  useEffect(() => {
    orderTrackFunc()
  }, [awbId])
  return (

    order ?
      <>
        <div className='px-4 mb-2'>
          <Link to="/dashboard/orders" className='text-decoration-none'> <i className="fa-solid fa-arrow-left-long" /> Back</Link>
        </div>
        
        <div className='border p-2 d-none'>
          <Invoice order={order} ref={contentRef} />
        </div>
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            <Typography variant="h4" gutterBottom>
              OrderId : {orderId}
            </Typography>
            <div>

            {/* <Button variant="contained" onClick={handlePrint}>Print Invoice</Button>  */}
            {/* <Button variant="contained" onClick={fetchDocuments}>Print Invoice & Label</Button> */}
            <Button variant="contained"  className='me-3' onClick={printLabel}>Print Label</Button>
            <Button variant="contained"  className='me-3' onClick={fetchLabel}>Download Label</Button>
            <Button variant="contained" onClick={fetchInvoice}>Download Invoice  </Button>
            </div>
          </Stack>
        </Container>

        <Container>
          <div className="row">
            <div className="col-4">
              <h5>Order Details</h5>
              <div>
                <p className='text-capitalize'> <span className='text-muted fw-bold '>User</span> : {order.address.receiver}</p>
                <p > <span className='text-muted fw-bold'>Total Price</span>  : &#8377; {Number(order.subtotal)}</p>
                <p > <span className='text-muted fw-bold'>Order Date</span> : {new Date(order.createdAt).toLocaleString()}</p>
                <p > <span className='text-muted fw-bold'>Payment Type</span> : {order.paymentType === "cod" ? "Cash on delivery" : "Online"}</p>
                {order.paymentStatus &&
                  <p > <span className='text-muted fw-bold'>Payment Status </span>: {order.paymentStatus}</p>}

              </div>

              {/* <div className=''>
                <h5 className='mb-2'>Order Status</h5>
                {order.orderStatus?.map((orderStatusValue) => (
                  <div className='borderNotLast pb-3 ps-3 position-relative' key={orderStatusValue.status}>
                    <span className='dot' />
                    <div className='orderStatus'>
                      <p className=' mb-0 text-capitalize'>{orderStatusValue.status}</p>
                      <small className='fs-12 text-muted'>{formatedDate(orderStatusValue.time)}  <span className='text-secondary ps-2'> ( {formatTime(orderStatusValue.time)} ) </span></small>
                    </div>
                  </div>
                ))}
              </div> */}


            </div>
            <div className="col-4">
              <h5>Address Details</h5>
              <div>
                <p className='text-capitalize'> <span className='text-muted fw-bold'>Receiver Name</span> : {order?.address?.receiver}</p>
                <div className='d-flex gap-2'> <span className='text-muted fw-bold'>Receiver Phone</span> :
                  <span>
                    <p className='mb-1'> {order.address.primaryNumber}</p>
                    {/* <p className='mb-1'>{order.address.country.code} {order.address.primaryNumber}</p> */}
                    {order?.address?.secondaryNumber ?
                      <p className='mb-1'>{order?.address?.country?.code} {order?.address?.secondaryNumber}</p> : null}
                  </span>
                </div>
                <p> <span className='text-muted fw-bold'>Address</span> : {order.address.address}</p>
                {order.address.nearBy ?
                  <p> <span className='text-muted fw-bold'>Near by</span> : {order.address.nearBy}</p> : null}
                <p> <span className='text-muted fw-bold'>City</span> : {order.address.city}</p>
                <p> <span className='text-muted fw-bold'>State</span> : {order.address.state}</p>
                <p> <span className='text-muted fw-bold'>Pincode</span> : {order.address.pincode}</p>



              </div>
            </div>
            <div className="col-4">
              <div className=''>
                <h5 className='mb-2'>Order Status</h5>
                {order.orderStatus?.map((orderStatusValue) => (
                  <div className='borderNotLast pb-3 ps-3 position-relative' key={orderStatusValue.status}>
                    <span className='dot' />
                    <div className='orderStatus'>
                      <p className=' mb-0 text-capitalize'>{orderStatusValue.status}</p>
                      <small className='fs-12 text-muted'>{formatedDate(orderStatusValue.time)}  <span className='text-secondary ps-2'> ( {formatTime(orderStatusValue.time)} ) </span></small>
                    </div>
                  </div>
                ))}
              </div>

              {shipmentTrack ?
              <div className=''>
                <h5 className='mb-2'>Order Tracking</h5>
                <div className='p-3 bg-white shadow-sm mb-5' style={{maxHeight : "300px", overflow : "auto"}} >
                {shipmentTrack?.map((Value, i) => (
                  <div className='borderNotLast pb-3 ps-3 position-relative' key={i}>
                    <span className='dot' />
                    <div className='orderStatus'>
                      {/* <p className=' mb-0 text-capitalize'>{Value.date}</p> */}
                      <small className='fs-12 text-muted'>{formatedDate(Value.date)}  <span className='text-secondary ps-2'> ( {formatTime(Value.date)} ) </span></small>
                      <p className=' mb-2 fs-14 text-muted'>{Value.activity}</p>
                      <p className=' mb-0 fs-12  '>{Value.location}</p>
                    </div>
                  </div>
                ))}
                </div>
              </div> 
              : null }
            </div>
          </div>
        </Container>

        <Container>
          <div className="row">
            <div className="col-12">
              <h5>Product Details</h5>
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>Product</th>
                    <th>Product Name</th>
                    <th>Price / Item</th>
                    <th>Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  {order && order?.product.map((productValue, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <img src={productValue?.thumbnailImage} alt="product" width={50} />
                        </td>
                        <td>
                          <div>
                            <div className="d-flex align-items-top gap-2">
                              <span className='text-muted fw-bold'>Product</span> :
                              <p className='mb-0'>{productValue?.productName}</p>
                            </div>
                          </div>
                        </td>
                        <td> &#x20B9; {productValue.discountedPrice}</td>
                        <td>{productValue.quantity}</td>
                      </tr>
                    )
                  }
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Container>

      </> : 
      <div className='px-4 mb-2'>
      <Link to="/dashboard/orders" className='text-decoration-none'> <i className="fa-solid fa-arrow-left-long" /> Back</Link>
    </div>
  )
}

export default OrderView